import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import ConfigAPI from '@/config/api'
import Session from '@/handler/session'

Vue.use(VueAxios, axios)

const instance = axios.create();

const servicesAPI = {

  obtenerDepositoZona() {      
    var url = ConfigAPI.baseURL + "deposits-zone" + Session.getToken();
    return instance.get(url);        
  },
}

export default servicesAPI;
